<template>
	<div class="modal_info modal_register_small">
		<div class="header-modal">
			<div class="title" v-html="$t('register.cant_create_because_not_entrepreneur')"></div>
			<div class="description" v-html="$t('register.not_entrepreneur_mail', {mail_entrepreneur: data.email})"></div>
		</div>

		<div class="submit-form">
			<button class="button-submit w-100" @click="closeModal">
				<div class="text">{{ $t('general.close') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {
				loaded: false,
			};
		},
		props: {
			data: Object
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			}
		}
	};
</script>