import Vue  from 'vue';
import Vuex from 'vuex';

import auth from './auth.js';
import packages from './packages.js';
import applications from './applications.js';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        auth,
        packages,
        applications
    },
  
    strict: debug
});