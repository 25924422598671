import axios from 'axios'
import Vue from 'vue'

export default {
    namespaced: true,

    state() {
        return {
            list: []
        };
    },

    actions: {
        getApplications({commit}) {
        	axios.get(`/applications`)
        	.then(({data}) => {
        		commit('SET_APPLICATIONS', data.data)
        	})
        }
    },

    getters: {
        getById: (state) => (idRequest) => {
            return state.list.find(el => el.id == idRequest)
        }
    },

    mutations: {
    	SET_APPLICATIONS(state, data) {
    		state.list = data
    	}
    }
}