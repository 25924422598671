<template>
	<div class="modal_info modal_register_small">
		<div class="header-modal">
			<div class="title" v-html="$t('login.modal.title')"></div>
			<div class="description" v-html="$t('login.modal.description', {email: email})"></div>
		</div>
		
		<div class="form-section-register">
			<div class="input-login-group-tbf mb-0">
				<div class="input-box" v-bind:class="{has_error: passwordError}">
					<div class="icon-right-new"><icon-password /></div>
					<input type="password" :placeholder="$t('register.password-placeholder')" class="input-text" v-model="password">
				</div>
				<div class="error-msg" v-if="passwordError">{{ $t('login.login-error') }}</div>
			</div>
		</div>
		
		<div class="submit-form">
			<button class="button-submit double grey" @click="closeModal">
				<div class="text">{{ $t('general.cancel') }}</div>
			</button>
			<button id="buttonSubmitLogin" class="button-submit double" @click="storeClone">
				<div class="loader-spin" v-if="loadingSubmit"></div>
				<div class="text" v-else>{{$t('login.login-button')}}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconPassword from '../Icons/Password'
	export default {
		data() {
			return {
				loaded: false,
				password: '',
				passwordError: false,
				email: '',
				loadingSubmit: false
			};
		},
		components: {
			IconPassword
		},
		props: {
			data: Object
		},
		mounted() {
			this.email = this.data.email
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
			storeClone(){
				var buttonName = 'buttonSubmitLogin'
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true;
				this.loadingSubmit = true;

				var app = this
				this.$auth.login({
					data: {
						email: app.email, password: app.password
					},
					redirect: {name: 'register-packages'},
					fetchUser: true,
					staySignedIn: true
				})
				.then(() => {
					this.closeModal()
				})
				.then(null, (res) => {
					let response = res.response.data
					if(response.status == 'error'){
						btnSubmit.disabled = false;
						this.loadingSubmit = false;
						this.passwordError = true
					}
				});
			}
		}
	};
</script>