<template>
    <component :is="layout">
        <router-view/>
    </component>
</template>

<script>
import LoginTemplate from '@/components/Layouts/LoginTemplate.vue'
import FullTemplate from '@/components/Layouts/FullTemplate.vue'
import PresentationTemplate from '@/components/Layouts/PresentationTemplate.vue'
import MainTemplate from '@/components/Layouts/MainTemplate.vue'

export default {
    data() {
        return {
            layout: 'full-template'
        }
    },
    components: {
        FullTemplate,
        PresentationTemplate,
        LoginTemplate,
        MainTemplate
    },
    watch: {
        $route(to, from) {
            if (['not-exist', 'forbbiden', 'not-found'].includes(this.$route.name) && !this.$auth.check()) {
                this.layout = 'presentation-template'
            } else {
                this.layout = this.$route.meta.layout
            }
        }
    },
    mounted() {
        if (['not-exist', 'forbbiden', 'not-found'].includes(this.$route.name) && !this.$auth.check()) {
            this.layout = 'presentation-template'
        } else {
            this.layout = this.$route.meta.layout
        }
    }
}
</script>