<template>
	<div class="modal_info modal_register_small">
		<div class="header-modal">
			<div class="title" v-html="$t('register.error_stripe_payment')"></div>
			<div class="description" v-html="$t('register.error_stripe_payment_desc', {timer})"></div>
		</div>

		<div class="submit-form">
			<button class="button-submit w-100" @click="goHome">
				<div class="text">{{ $t('navbar.homepage') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconPassword from '../Icons/Password'
	export default {
		data() {
			return {
				loaded: false,
				timer: 10
			};
		},
		components: {
			IconPassword
		},
		watch: {
			timer: {
				handler(value) {
					if (value > 0) {
						setTimeout(() => {
							this.timer--;
						}, 1000);
					}else{
						this.goHome()
					}

				},
				immediate: true 
			}
		},
		mounted() {

		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
			goHome(){
				this.$router.push({name: 'homepage'})
			}
		}
	};
</script>