<template>
	<div class="sidebar-mobile" v-if="$auth.ready() && $auth.check()">
		<div class="main-menu">
			<div class="item-menu">
				<router-link :to="{name: 'homepage' }">
					<icon-home class="icon-item"/>
				</router-link>
			</div>
			<div class="item-menu">
				<router-link :to="{name: 'dashboard'}">
					<icon-dashboard class="icon-item"/>
				</router-link>
			</div>
			<div class="item-menu" v-if="$auth.user().rights.mini_admin == 'true'">
				<router-link :to="{name: 'admin-users'}">
					<icon-tbf class="icon-item"/>
				</router-link>
			</div>
			<div class="item-menu" v-if="$auth.user().rights.mini_admin == 'true'">
				<router-link :to="{name: 'admin-report'}">
					<icon-money class="icon-item"/>
				</router-link>
			</div>
			<div class="item-menu">
				<a @click="$auth.logout()">
					<icon-logout class="icon-item"/>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	import IconDashboard from '../Icons/Dashboard'
	import IconHome from '../Icons/Home'
	import IconTbf from '../Icons/Tbf'
	import IconMoney from '../Icons/Money'
	import IconLogout from '../Icons/Logout'

	export default {
		data() {
			return {

			};
		},
		components: {
			IconDashboard,
			IconHome,
			IconTbf,
			IconMoney,
			IconLogout
		},
		methods: {
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			}
		}
	};
</script>