import axios from 'axios'
import Vue from 'vue'

export default {
    namespaced: true,

    state() {
        return {
            list: []
        };
    },

    actions: {
        getPackages({commit}) {
        	axios.get(`${process.env.VUE_APP_ORGANIGRAM_LINK}/api/instance-packages`)
        	.then(({data}) => {
        		commit('SET_PACKAGES', { soft: 'organigram', packages: data.data})
        	})

            axios.get(`${process.env.VUE_APP_PROCEDURES_LINK}/api/instance-packages`)
            .then(({data}) => {
                commit('SET_PACKAGES', { soft: 'procedure', packages: data.data})
            })

            axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/instance-packages`)
            .then(({data}) => {
                commit('SET_PACKAGES', { soft: 'objective', packages: data.data})
            })
        }
    },

    getters: {
        getBySoft: (state) => (softRequest) => {
            return state.list.find(el => el.soft == softRequest)
        }
    },

    mutations: {
    	SET_PACKAGES(state, data) {
    		state.list.push(data)
    	}
    }
}