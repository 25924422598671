<template>
	<div class="modal_info modal_delete">
		<div class="header-submodal">
			<div class="image"><img src="/build/images/trash-icon.svg"></div>
			<div class="title">{{ $t('delete.title') }}</div>
		</div>
		<div class="description-modal">
			{{ $t('delete.question',{text: $t('delete.' + data.type)}) }}
			{{ $t('delete.atention') }}
		</div>

		<div class="button-actions">
			<button class="btn-tbf white center" @click="cancelDelete">
				<div class="text">{{ $t('general.cancel') }}</div>
			</button>
			<button id="buttonSubmitDelete" class="btn-tbf red center" @click="submitDelete">
				<div class="loader"></div>
				<div class="text">{{ $t('delete.delete') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {
			};
		},
		props: {
			data: Object
		},
		components: {
		},
		mounted() {

		},
		methods: {
			cancelDelete(){
				this.$emit("close_modal");
			},
			submitDelete(){
				var buttonName = `buttonSubmitDelete`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				switch (this.data.type) {
					case 'softwareUpdate':
						this.deleteSoftware()
						break;
				}
			},
			deleteSoftware(){
				axios.delete(`/software-updates/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage()
					}, 100)
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			refreshPage(){
				var buttonName = `buttonSubmitDelete`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				switch (this.data.from) {
					case 'index_software_updates':
						this.$root.$emit('refreshSoftwareUpdatesIndex')
						break;
				}

				btnSubmitLoader.classList.add('finish')
				setTimeout(()=>{
					btnSubmitText.innerHTML = this.$t('btn-submit.success')
					btnSubmit.classList.add('completed')
					btnSubmitLoader.classList.remove('onProgress', 'finish')
					btnSubmit.classList.remove('loading')
					setTimeout(()=>{
						btnSubmit.classList.remove('completed')
						btnSubmitText.innerHTML = this.$t('delete.delete')
						this.$emit("close_modal");
					}, 1000)
				}, 300)
			}
		}
	};
</script>