<template>
	<div class="navbar-tbf row-space-tbf">
		<div class="space-left"></div>
		<div class="content">
			<div class="title">
				<h1>{{ navbarTitle }}</h1>
			</div>
			<div class="profile-items">
				<div class="digital-settings dropdown" v-if="$auth.user().rights.mini_admin == 'true'">
					<div class="open-dropdown" ref="dropdownAdminSettings" id="dropdownAdminSettings" data-toggle="dropdown">
						<icon-admin-settings />
					</div>

					<div class="dropdown-menu dropdown-menu-center" id="dropdownAdminSettings" aria-labelledby="dropdownAdminSettings">
						<div class="label">Rapoarte</div>
						<div class="menu">
							<router-link :to="{name: 'admin-users'}" class="menu-item">
								<div class="icon"><icon-users /></div>
								<div class="text">Clienti detaliat</div>
							</router-link>
							<router-link :to="{name: 'admin-report'}" class="menu-item">
								<div class="icon"><icon-money /></div>
								<div class="text">Venit general</div>
							</router-link>
							<router-link :to="{name: 'admin-report-daily'}" class="menu-item">
								<div class="icon"><icon-report-daily /></div>
								<div class="text">Venit detaliat</div>
							</router-link>
							<router-link :to="{name: 'admin-all-users'}" class="menu-item">
								<div class="icon margin"><icon-users-admin /></div>
								<div class="text">Lista utilizatori</div>
							</router-link>
						</div>
						<div class="label mt-15">Setari</div>
						<div class="menu">
							<router-link :to="{name: 'admin-discount-tokens'}" class="menu-item">
								<div class="icon"><icon-discount /></div>
								<div class="text">Discount token</div>
							</router-link>
							<router-link :to="{name: 'admin-updates'}" class="menu-item">
								<div class="icon"><icon-updates-notifications /></div>
								<div class="text">Actualizari sistem</div>
							</router-link>
							<router-link :to="{name: 'admin-future-updates'}" class="menu-item">
								<div class="icon"><icon-future-updates /></div>
								<div class="text">Viitoare actualizari</div>
							</router-link>
						</div>
					</div>
				</div>

				<div class="digital-applications dropdown">
					<div class="open-dropdown" ref="dropdownApplications" id="dropdownApplications" data-toggle="dropdown">
						<icon-dots-square />
					</div>

					<div class="dropdown-menu dropdown-menu-center" id="dropdownApplications" aria-labelledby="dropdownApplications">
						<div class="application-item">
							<a class="application" href="https://obiective.tbf.ro" target="_blank">
								<div class="icon"><img src="/build/images/objectives.svg"></div>
								<div class="text">{{ $t('navbar.ojectives') }}</div>
							</a>
						</div>
						<div class="application-item">
							<a class="application" href="https://organigrama.tbf.ro" target="_blank">
								<div class="icon"><img src="/build/images/organigrama.svg"></div>
								<div class="text">{{ $t('navbar.organigram') }}</div>
							</a>
						</div>
						<div class="application-item">
							<a class="application" href="https://proceduri.tbf.ro" target="_blank">
								<div class="icon"><img src="/build/images/procedures.svg"></div>
								<div class="text">{{ $t('navbar.procedures') }}</div>
							</a>
						</div>
					</div>
				</div>

				<div class="profile-navbar dropdown" v-if="$auth.ready() && $auth.check()">

					<img :src="$auth.user().avatar" ref="dropdownUser" id="dropdownUser" data-toggle="dropdown" v-if="$auth.user().avatar">
					<div class="user-circle" ref="dropdownUser" id="dropdownUser" data-toggle="dropdown" v-else>
						<icon-user />
					</div>

					<div class="dropdown-menu dropdown-menu-right" id="dropdownApplications" aria-labelledby="dropdownApplications" v-if="$auth.ready()">
						<div class="user-data">
							<div class="name">{{ $auth.user().first_name + ' ' + $auth.user().last_name }}</div>
							<div class="email">{{ $auth.user().email }}</div>
						</div>
						<div class="dropdown-item">
							<div class="text" @click="$auth.logout()" >{{ $t('navbar.log_out') }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="space-right"></div>
	</div>
</template>

<script>
	import IconDotsSquare from '../Icons/DotsSquare'
	import IconUser from '../Icons/User'
	import IconAdminSettings from '../Icons/AdminSettings'
	import IconMoney from '../Icons/Money'
	import IconUsers from '../Icons/Users'
	import IconReportDaily from '../Icons/ReportDaily'
	import IconDiscount from '../Icons/Discount'
	import IconUpdatesNotifications from '../Icons/UpdatesNotifications'
	import IconFutureUpdates from '../Icons/FutureUpdates'
	import IconUsersAdmin from '../Icons/UsersAdmin'

	export default {
		data() {
			return {
				navbarTitle: '',
			};
		},
		components: {
			IconDotsSquare,
			IconAdminSettings,
			IconUser,
			IconMoney,
			IconReportDaily,
			IconDiscount,
			IconUpdatesNotifications,
			IconFutureUpdates,
			IconUsers,
			IconUsersAdmin
		},
		mounted() {
			this.$root.$on("navbar_title", (title) => {
				this.navbarTitle = title
			});
		},
		methods: {
			
		}
	};
</script>