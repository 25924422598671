<template>
	<div class="modal_info email-invoices">
		<div class="header-submodal">
			<div class="title">Email-uri notificare factura "{{ softName }}"</div>
		</div>
		<div class="description-modal">Puteti seta mai multe adrese de email unde sa trimiteti email-urile.</div>



		<div class="form-textarea">
			<div v-if="email_error" class="email_error">{{ email_error }}</div>
			<div class="input-group w-100 mb-0">
				<div class="emails-invoices">
					<div class="main-input">
						<div class="add-email">
							<input :disabled="keyEnter" type="email" placeholder="Adauga o adresa de email" class="input-text email-invoices" v-model="inputEmail" @keydown.enter="saveEmailsNotifications(inputEmail, true)">
							<div class="icon-right" v-if="inputEmail != '' && inputEmail" @click.stop="clearEmailInput"><icon-close class="icon-clear" /></div>
							<div class="btn-box">
								<button v-if="inputEmail" id="buttonAddEmails" class="btn-tbf blue btn-save center" @click="saveEmailsNotifications(inputEmail, false)">
									<div class="loader"></div>
									<span class="text">Adauga</span>
								</button>
							</div>
						</div>
					</div>

					<div class="textarea-selected-emails" v-if="emailsNotification.length > 0">
						<span v-for="email, index in emailsNotification" class="name">
							{{ email }}
							<button class="remove" @click="emailsNotification.splice(index,1)"><icon-close /></button>
						</span>
					</div>
				</div>
			</div>
		</div>

		<div class="button-actions">
			<button class="btn-tbf white center" @click="closeModal">
				<div class="text">{{ $t('general.cancel') }}</div>
			</button>
			<button id="submitButtonEmailsInvoice" class="btn-tbf blue center" @click="saveMails">
				<div class="loader"></div>
				<div class="text">Actualizeaza</div>
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.modal_info.email-invoices{
		.description-modal{
			text-align: left;
			max-width: 100%;
			.color-red{
				color: $red;
			}
			.color-black{
				color: $primary;
			}
		}
		.form-textarea{
			margin-top: 30px;
			.email_error{
				font-size: 14px;
				color: $red;
				margin-bottom: 10px;
			}
		}
		.emails-invoices{
			border-radius: 12px;
			background: #FFF;
			padding: 15px;
			border: 1px solid $borderCard;
			font-size: 14px;
			position: relative;
			&.show {
				display: flex;
			}
			&.has_error{
				border-color: $red;
			}
			.edit-invoice{
				display: flex;
				align-items: center;
				margin-left: 10px;
				width: 20px;
				cursor: pointer;
			}
			.empty-emails,
			.name {
				display: flex;
				flex: 1;
				display: inline-block;
			}
			.main-input{
				.add-email{
					display: flex;
					align-items: center;
				}
				.icon-right{
					cursor: pointer;
					margin-right: 15px;
				}
				.input-text{
					height: 25px;
					width: 100%;
					border: none;
					outline: none;
					padding-right: 35px;
				}
				button{
					height: 25px;
					min-width: 90px !important;
					font-size: 12px
				}
			}
			.textarea-selected-emails{
				margin-top: 15px;
				.name{
					font-size: 12px;
					background-color: #FFF;
					border: 1px solid #dcdee1;
					display: inline-flex;
					align-items: center;
					border-radius: 5px;
					padding: 5px 10px;
					margin-right: 10px;
					margin-bottom: 8px;
					.remove{
						background: transparent;
						border: 0;
						padding: 0;
						display: flex;
						margin-left: 10px;
						cursor: pointer;
						&:focus{
							outline: none;
						}
						svg{
							height: 8px;
							width: auto;
						}
					}
				}
			}
		}
	}
</style>

<script type="text/javascript">
	import IconClose from '../Icons/Close'
	export default {
		data() {
			return {
				showButtons: false,
				email_error: '',
				softName: '',
				keyEnter: false,
				inputEmail: '',
				emailsNotification: []
			};
		},
		props: {
			data: Object
		},
		components: {
        	IconClose
        },
		async mounted() {
			this.getEmails()
		},
		methods: {
			getEmails(){
				switch (this.data.soft){
					case "objectives":
						var objectivesHost = this.$auth.user().applications_host.find(el => el.name == 'Obiective')
						axios.get(`${objectivesHost.host}/api/${this.data.master_instance_id}/instance-notifications`, { params: {token: objectivesHost.api_key}})
						.then(({data}) =>{ 
							this.emailsNotification = data.data;
							this.softName = 'Obiective'
							setTimeout(() => { this.showButtons = true }, 100)
						})
						break;
					case "procedures":
						var proceduresHost = this.$auth.user().applications_host.find(el => el.name == 'Proceduri')
						axios.get(`${proceduresHost.host}/api/${this.data.master_instance_id}/instance-notifications`, { params: {token: proceduresHost.api_key}})
						.then(({data}) =>{ 
							this.emailsNotification = data.data;
							this.softName = 'Proceduri'
							setTimeout(() => { this.showButtons = true }, 100)
						})
						break;
					case "organigram":
						var organigramHost = this.$auth.user().applications_host.find(el => el.name == 'Organigrama')
						axios.get(`${organigramHost.host}/api/${this.data.master_instance_id}/instance-notifications`, { params: {token: organigramHost.api_key}})
						.then(({data}) =>{ 
							this.emailsNotification = data.data;
							this.softName = 'Organigrama'
							setTimeout(() => { this.showButtons = true }, 100)
						})
						break;
				}
			},
			closeModal(){
				this.$emit("close_modal");
			},
			saveMails(){
				var btnSubmit = document.getElementById('submitButtonEmailsInvoice');
				var btnSubmitLoader = document.querySelector(`#submitButtonEmailsInvoice .loader`)
				var btnSubmitText = document.querySelector(`#submitButtonEmailsInvoice .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				switch (this.data.soft){
					case "objectives":
						var objectivesHost = this.$auth.user().applications_host.find(el => el.name == 'Obiective')
						axios.post(`${objectivesHost.host}/api/${this.data.master_instance_id}/set-notification`, {email: this.emailsNotification.join(","), token: objectivesHost.api_key})
						.then(() =>{ 
							btnSubmitLoader.classList.add('finish')
							setTimeout(()=>{
								btnSubmitText.innerHTML = this.$t('btn-submit.success')
								btnSubmit.classList.add('completed')
								btnSubmitLoader.classList.remove('onProgress', 'finish')
								btnSubmit.classList.remove('loading')
								setTimeout(()=>{
									btnSubmit.classList.remove('completed')
									btnSubmitText.innerHTML = 'Actualizeaza'
									btnSubmit.disabled = false
								}, 300);
							}, 300)
						}).catch(error => {
							btnSubmitLoader.classList.add('finish')
							setTimeout(()=>{
								btnSubmitText.innerHTML = 'Eroare'
								btnSubmit.classList.add('error')
								btnSubmitLoader.classList.remove('onProgress', 'finish')
								btnSubmit.classList.remove('loading')
								setTimeout(()=>{
									btnSubmit.classList.remove('error')
									btnSubmitText.innerHTML = 'Actualizeaza'
									btnSubmit.disabled = false
									this.email_error = 'Obligatoriu';
								}, 1000)
							}, 300)
						})
						break;
					case "procedures":
						var proceduresHost = this.$auth.user().applications_host.find(el => el.name == 'Proceduri')
						axios.post(`${proceduresHost.host}/api/${this.data.master_instance_id}/set-notification`, {email: this.emailsNotification.join(","), token: proceduresHost.api_key})
						.then(() =>{ 
							btnSubmitLoader.classList.add('finish')
							setTimeout(()=>{
								btnSubmitText.innerHTML = this.$t('btn-submit.success')
								btnSubmit.classList.add('completed')
								btnSubmitLoader.classList.remove('onProgress', 'finish')
								btnSubmit.classList.remove('loading')
								setTimeout(()=>{
									btnSubmit.classList.remove('completed')
									btnSubmitText.innerHTML = 'Actualizeaza'
									btnSubmit.disabled = false
								}, 300);
							}, 300)
						}).catch(error => {
							btnSubmitLoader.classList.add('finish')
							setTimeout(()=>{
								btnSubmitText.innerHTML = 'Eroare'
								btnSubmit.classList.add('error')
								btnSubmitLoader.classList.remove('onProgress', 'finish')
								btnSubmit.classList.remove('loading')
								setTimeout(()=>{
									btnSubmit.classList.remove('error')
									btnSubmitText.innerHTML = 'Actualizeaza'
									btnSubmit.disabled = false
									this.email_error = 'Obligatoriu';
								}, 1000)
							}, 300)
						})
						break;
					case "organigram":
						var organigramHost = this.$auth.user().applications_host.find(el => el.name == 'Organigrama')
						axios.post(`${organigramHost.host}/api/${this.data.master_instance_id}/set-notification`, {email: this.emailsNotification.join(","), token: organigramHost.api_key})
						.then(() =>{ 
							btnSubmitLoader.classList.add('finish')
							setTimeout(()=>{
								btnSubmitText.innerHTML = this.$t('btn-submit.success')
								btnSubmit.classList.add('completed')
								btnSubmitLoader.classList.remove('onProgress', 'finish')
								btnSubmit.classList.remove('loading')
								setTimeout(()=>{
									btnSubmit.classList.remove('completed')
									btnSubmitText.innerHTML = 'Actualizeaza'
									btnSubmit.disabled = false
								}, 300);
							}, 300)
						}).catch(error => {
							btnSubmitLoader.classList.add('finish')
							setTimeout(()=>{
								btnSubmitText.innerHTML = 'Eroare'
								btnSubmit.classList.add('error')
								btnSubmitLoader.classList.remove('onProgress', 'finish')
								btnSubmit.classList.remove('loading')
								setTimeout(()=>{
									btnSubmit.classList.remove('error')
									btnSubmitText.innerHTML = 'Actualizeaza'
									btnSubmit.disabled = false
									this.email_error = 'Obligatoriu';
								}, 1000)
							}, 300)
						})
						break;
				}
			},
			clearEmailInput(){
				this.inputEmail = '';
				this.email_error = '';
				$('.emails-invoices').removeClass('has_error');
			},
			saveEmailsNotifications(newEmail, bool){
				this.keyEnter = bool
				var buttonName = 'buttonAddEmails'
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				
				var regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

				if(regexEmail.test(String(newEmail).toLowerCase())) {
					this.email_error = ''
					$('.emails-invoices').removeClass('has_error')

					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							this.emailsNotification.push(newEmail.toLowerCase())
							this.inputEmail = '';
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = 'Adauga'
							btnSubmit.disabled = false
						}, 300);
					}, 300)
				} else {
					this.email_error = 'Email invalid'
					$('.emails-invoices').addClass('has_error')
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = 'Eroare'
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = 'Adauga'
							btnSubmit.disabled = false
							this.keyEnter = false
						}, 1000)
					}, 300)
				}
			}
		}
	};
</script>