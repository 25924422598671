<template>
	<div class="modal_info change-subscription">
		<div class="header-submodal">
			<div class="title">Modificare abonament "{{ softName }}"</div>
		</div>
		<div class="description-modal">Aceasta actiune va modifica abonamentul in stripe si in soft.</div>

		<div class="list-packages">
			<div class="package-box" v-for="packageItem in listPackages">
				<div class="package-item" :class="{active: selectedPackage == packageItem.token, current: packageItem.active_subscription_details ? packageItem.package_id == packageItem.active_subscription_details.package_id : false}" @click="selectedPackage = packageItem.token">{{ packageItem.package_unique_name }}</div>
			</div>
		</div>

		<div class="button-actions">
			<button class="btn-tbf white center" @click="closeModal">
				<div class="text">{{ $t('general.cancel') }}</div>
			</button>
			<button id="submitBtnChangeSubscription" class="btn-tbf blue center" @click="updateSubscription">
				<div class="loader"></div>
				<div class="text">{{ $t('general.update') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from '../Icons/Close'

	export default {
		data() {
			return {
				showButtons: false,
				softName: '',
				selectedPackage: '',
				listPackages: []
			};
		},
		props: {
			data: Object
		},
		components: {
        	IconClose
        },
		async mounted() {
			this.getPackages()
		},
		methods: {
			getPackages(){
				switch (this.data.soft){
					case "objectives":
						var objectivesHost = this.$auth.user().applications_host.find(el => el.name == 'Obiective')
						axios.get(`${objectivesHost.host}/api/packages`, { params: {master_instance_id: this.data.master_instance_id, from_external: true}})
						.then(({data}) =>{ 
							this.listPackages = data.data;
							this.softName = 'Obiective'
							setTimeout(() => { this.showButtons = true }, 100)
						})
						break;
					case "procedures":
						var proceduresHost = this.$auth.user().applications_host.find(el => el.name == 'Proceduri')
						axios.get(`${proceduresHost.host}/api/packages`, { params: {master_instance_id: this.data.master_instance_id, from_external: true}})
						.then(({data}) =>{ 
							this.listPackages = data.data;
							this.softName = 'Proceduri'
							setTimeout(() => { this.showButtons = true }, 100)
						})
						break;
					case "organigram":
						var organigramHost = this.$auth.user().applications_host.find(el => el.name == 'Organigrama')
						axios.get(`${organigramHost.host}/api/packages`, { params: {master_instance_id: this.data.master_instance_id, from_external: true}})
						.then(({data}) =>{ 
							this.listPackages = data.data;
							this.softName = 'Organigrama'
							setTimeout(() => { this.showButtons = true }, 100)
						})
						break;
				}
			},
			closeModal(){
				this.$emit("close_modal");
			},
			updateSubscription(){
				var btnSubmit = document.getElementById('submitBtnChangeSubscription');
				var btnSubmitLoader = document.querySelector(`#submitBtnChangeSubscription .loader`)
				var btnSubmitText = document.querySelector(`#submitBtnChangeSubscription .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				switch (this.data.soft){
					case "objectives":
						var objectivesHost = this.$auth.user().applications_host.find(el => el.name == 'Obiective')
						axios.post(`${objectivesHost.host}/api/${this.data.master_instance_id}/change-plan`, {package_token: this.selectedPackage, token: objectivesHost.api_key})
						.then(() =>{ 
							this.$root.$emit('syncMiniAdmin')
							btnSubmitLoader.classList.add('finish')
							setTimeout(()=>{
								btnSubmitText.innerHTML = this.$t('btn-submit.success')
								btnSubmit.classList.add('completed')
								btnSubmitLoader.classList.remove('onProgress', 'finish')
								btnSubmit.classList.remove('loading')
								setTimeout(()=>{
									this.$emit("close_modal");
									this.$root.$emit('closeShowInstance')
								}, 1300);
							}, 300)
						}).catch(error => {
							btnSubmitLoader.classList.add('finish')
							setTimeout(()=>{
								btnSubmitText.innerHTML = 'Eroare'
								btnSubmit.classList.add('error')
								btnSubmitLoader.classList.remove('onProgress', 'finish')
								btnSubmit.classList.remove('loading')
								setTimeout(()=>{
									btnSubmit.classList.remove('error')
									btnSubmitText.innerHTML = 'Actualizeaza'
									btnSubmit.disabled = false
									this.email_error = 'Obligatoriu';
								}, 1000)
							}, 300)
						})
						break;
					case "procedures":
						var proceduresHost = this.$auth.user().applications_host.find(el => el.name == 'Proceduri')
						axios.post(`${proceduresHost.host}/api/${this.data.master_instance_id}/change-plan`, {package_token: this.selectedPackage, token: proceduresHost.api_key})
						.then(() =>{ 
							this.$root.$emit('syncMiniAdmin')
							btnSubmitLoader.classList.add('finish')
							setTimeout(()=>{
								btnSubmitText.innerHTML = this.$t('btn-submit.success')
								btnSubmit.classList.add('completed')
								btnSubmitLoader.classList.remove('onProgress', 'finish')
								btnSubmit.classList.remove('loading')
								setTimeout(()=>{
									this.$emit("close_modal");
									this.$root.$emit('closeShowInstance')
								}, 1300);
							}, 300)
						}).catch(error => {
							btnSubmitLoader.classList.add('finish')
							setTimeout(()=>{
								btnSubmitText.innerHTML = 'Eroare'
								btnSubmit.classList.add('error')
								btnSubmitLoader.classList.remove('onProgress', 'finish')
								btnSubmit.classList.remove('loading')
								setTimeout(()=>{
									btnSubmit.classList.remove('error')
									btnSubmitText.innerHTML = 'Actualizeaza'
									btnSubmit.disabled = false
									this.email_error = 'Obligatoriu';
								}, 1000)
							}, 300)
						})
						break;
					case "organigram":
						var organigramHost = this.$auth.user().applications_host.find(el => el.name == 'Organigrama')
						axios.post(`${organigramHost.host}/api/${this.data.master_instance_id}/change-plan`, {package_token: this.selectedPackage, token: organigramHost.api_key})
						.then(() =>{ 
							this.$root.$emit('syncMiniAdmin')
							btnSubmitLoader.classList.add('finish')
							setTimeout(()=>{
								btnSubmitText.innerHTML = this.$t('btn-submit.success')
								btnSubmit.classList.add('completed')
								btnSubmitLoader.classList.remove('onProgress', 'finish')
								btnSubmit.classList.remove('loading')
								setTimeout(()=>{
									this.$emit("close_modal");
									this.$root.$emit('closeShowInstance')
								}, 1300);
							}, 300)
						}).catch(error => {
							btnSubmitLoader.classList.add('finish')
							setTimeout(()=>{
								btnSubmitText.innerHTML = 'Eroare'
								btnSubmit.classList.add('error')
								btnSubmitLoader.classList.remove('onProgress', 'finish')
								btnSubmit.classList.remove('loading')
								setTimeout(()=>{
									btnSubmit.classList.remove('error')
									btnSubmitText.innerHTML = 'Actualizeaza'
									btnSubmit.disabled = false
									this.email_error = 'Obligatoriu';
								}, 1000)
							}, 300)
						})
						break;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.modal_info.change-subscription{
		.description-modal{
			text-align: center;
			max-width: 100%;
			.color-red{
				color: $red;
			}
			.color-black{
				color: $primary;
			}
		}
		.list-packages{
			display: flex;
			flex-wrap: wrap;
			margin-top: 20px;
			.package-box{
				flex: 0 0 50%;
				max-width: 50%;
				padding: 0 10px;
				margin-top: 10px;
				.package-item{
					border: 1px solid $borderCard;
					border-radius: 8px;
					padding: 10px;
					font-size: 14px;
					color: $primary;
					cursor: pointer;
					font-weight: 500;
					&:hover{
						background: #f9f9f9;
					}
					&.active {
						background: $bgBlue;
						border: 1px solid $borderBlue;
						color: #FFF;
					}
					&.current {
						background: $bgBlue;
						border: 1px solid $borderBlue;
						color: #FFF;
						opacity: 0.4;
						cursor: not-allowed;
					}
				}
			}
		}
	}
</style>