<template>
	<div class="modal_info modal_has_applications">
		<div class="header-submodal">
			<div class="title">{{ $t('register.already_has_applications') }}</div>
		</div>
		<div class="description-modal" v-html="$t('register.already_has_applications_timer', {timer})"></div>

		<div class="button-actions">
			<button class="btn-tbf blue center" @click="goDashboard">
				<div class="loader"></div>
				<div class="text">{{ $t('navbar.dashboard') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconPassword from '../Icons/Password'
	export default {
		data() {
			return {
				loaded: false,
				timer: 7
			};
		},
		components: {
			IconPassword
		},
		watch: {
			timer: {
				handler(value) {
					if (value > 0) {
						setTimeout(() => {
							this.timer--;
						}, 1000);
					}else{
						this.goDashboard()
					}

				},
				immediate: true 
			}
		},
		mounted() {

		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
			goDashboard(){
				this.$router.push({name: 'dashboard'})
			}
		}
	};
</script>