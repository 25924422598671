<template>
    <div class="modal-tbf modal-report-vouchers">
        <div class="container-modal opacity-page">
            <div class="header-modal">
                <div class="title">
                    Vouchere / {{ $t(`admin_users.soft_name.${data.soft}`) }} / {{ data.name }}
                </div>
                <div class="actions">
                    <button class="btn-tbf white only-icon close-btn" @click="cancelModal"><icon-close class="icon-close" /></button>
                </div>
            </div>

            <template v-if="loadedList">
                <div class="data-list vouchers" v-if="data.entries && Object.values(data.entries).length">
                    <div class="row-space-tbf header-list">
                        <div class="content">
                            <div class="column-filter column-voucher-name">
                                <div class="text">Voucher</div>
                            </div>
                            <div class="column-filter column-voucher-price">
                                <div class="text">Reducere</div>
                            </div>
                            <div class="column-filter column-voucher-quantity">
                                <div class="text">Cantitate</div>
                            </div>
                        </div>
                    </div>

                    <div class="row-space-tbf row-list-item" v-for="entry in Object.values(data.entries)">
                        <div class="content">
                            <div v-if="$resize && $mq.above(781)" class="border-hover"></div>
                            <div class="column-name-title column-voucher-name">
                                <div class="text">{{ entry.name }}</div>
                            </div>
                            <div class="column-simple-text column-voucher-price">
                                <div class="text">{{ entry.discount }}</div>
                            </div>
                            <div class="column-simple-text column-voucher-quantity">
                                <div class="text">{{ entry.total }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row-space-tbf list-empty modal-empty" v-else>
                    <div class="content full">
                        <div class="title">{{ $t('general.empty')}}</div>
                    </div>
                </div>
            </template>
            <loader-items-modal-list v-else/>
        </div>
    </div>
</template>

<script>
    import IconClose from '../Icons/Close'
    import LoaderItemsModalList from '../PagesLoaders/ItemsModalList'

    export default {
        data() {
            return {
                loadedList: false,
            };
        },
        props: {
            data: Object
        },
        components: {
            IconClose,
            LoaderItemsModalList
        },
        async mounted() {
            setTimeout(() => {
                $('.opacity-page').addClass('show')
                setTimeout(() => {
                    this.loadedList = true
                }, 0)
            }, 0)
        },
        methods: {
            cancelModal(){
                this.$emit("close_modal");
            }
        }
    };
</script>

<style lang="scss">
    .modal-report-vouchers{
        .list-empty .content{
            border-top: 0;
            padding-top: 30px;
        }
        .data-list{
            padding-bottom: 0;
        }
    }
    .data-list{
        &.vouchers{
            .column-voucher-name{
                flex: 1 1 auto;
            }
            .column-voucher-price{
                flex: 0 0 150px;
                justify-content: flex-end;
                display: flex;
            }
            .column-voucher-quantity{
                flex: 0 0 150px;
                justify-content: flex-end;
                display: flex;
            }
        }
    }
</style>